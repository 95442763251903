//@ts-nocheck
import React, { useState } from "react";
import { Button, Table } from "antd";
import { useQuery } from "react-query";
import { getBudgets } from "api/budgets";
import { useTranslation } from "react-i18next";
import FilterForm from "./FilterForm";
import QEmptyIndex from "components/layout/emptyIndex";
import { PlusOutlined } from "@ant-design/icons";
import NoBudgetData from "assets/icons/NoBudgetData.svg";
import { useTableColumns } from "hooks/budgets";

const BudgetTable = ({ permissions }) => {
  const { t } = useTranslation();

  const { columns } = useTableColumns(permissions);

  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [sorter, setSorter] = useState("id desc");
  const [filterApplied, setFilterApplied] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["budgets", filters, currentPage, pageSize, sorter],
    queryFn: () => getBudgets(currentPage, filters, pageSize, sorter),
  });

  const extractYear = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return date.getFullYear();
    }
    return null;
  };

  const updatedData = data?.records?.map((record) => ({
    ...record,
    fiscal_year: extractYear(record.fiscal_year),
    kind: t(`budgets.attributes.${record.kind}`),
  }));

  const handleTableChange = (pagination, _, sorter) => {
    const order = sorter.order ? sorter.order : "descend";
    const columnKey = sorter.columnKey ? sorter.columnKey : "id"; 
    setSorter(`${columnKey} ${order === "descend" ? "desc" : "asc"}`);
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleFilterSubmit = (values) => {
    setCurrentPage(1);
    setFilters(values);
    setFilterApplied(true);
  };

  const handleReset = () => {
    setFilters({});
    setCurrentPage(1);
    setPageSize(15);
    setSorter("");
    setFilterApplied(false);
  };
  return (
    <div>
      {!filterApplied &&
      !isLoading &&
      (!data || !data.records || data.records.length === 0) ? (
        <QEmptyIndex
          title={t("budgets.index.onboarding_page_title")}
          subTitle={t("budgets.index.onboarding_page_description")}
          Icon={NoBudgetData}
        >
          {permissions["write"] && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              href="/tenant/budgets/new"
            >
              {t("budgets.index.create_new_budget")}
            </Button>
          )}
        </QEmptyIndex>
      ) : (
        <>
          <FilterForm onSubmit={handleFilterSubmit} onReset={handleReset} />
          <Table
            columns={columns}
            dataSource={updatedData}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: data?.pagination.total_entries,
              showSizeChanger: true,
              pageSizeOptions: ["5", "15", "50", "100"],
            }}
            loading={isLoading}
            onChange={handleTableChange}
            rowKey="id"
            locale={{
              emptyText: t("budgets.noData"),
            }}
          />
        </>
      )}
    </div>
  );
};

export default BudgetTable;
