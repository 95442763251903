//@ts-nocheck
import { TFunction } from "i18next";
import React from "react";
import { CardData } from "types/referrals";

export const generateCardData = (
  rewardsCount: number,
  t: TFunction,
  language: string
): CardData[] => {
  return [
    {
      id: 1,
      rewardsCount,
      title: t("referrals.index.referral_card.title"),
      description: t("referrals.index.referral_card.subtitle"),
      showInput: true,
    },
    {
      id: 2,
      rewardsCount: rewardsCount,
      title:
        rewardsCount === 0
          ? t("referrals.index.gift_card.rewards_title")
          : t("referrals.index.gift_card.congrats_title"),
      description: generateCardDescription(rewardsCount, t, language),
      showInput: false,
      color: rewardsCount > 0 ? "#256F3A" : undefined,
    },
  ];
};

export const generateCardDescription = (
  rewardsCount: number,
  t: TFunction,
  language: string
) => {
  return rewardsCount === 0 ? (
    t("referrals.index.gift_card.rewards_subtitle")
  ) : (
    <>
      {t("referrals.index.gift_card.congrats_subtitle")}
      <span style={{ color: "#256F3A", fontWeight: "bold" }}>
        {t('referrals.index.gift_card.congrats_subtitle_month', {count:rewardsCount})}
      </span>
      {t("referrals.index.gift_card.congrats_subtitle_3")}
    </>
  );
};