//@ts-nocheck
import React, { useEffect } from 'react'
import { Form, Input, Flex, Button, message } from 'antd'
import { useTranslation } from 'react-i18next'
import QPageLayout from 'components/layout/QPageLayout';
import BackButton from 'components/layout/BackButton';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';



export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
          title: t('activerecord.attributes.dimension.new'),
      },
  ]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 3 }
    
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 9 },
  },
};

const NewDimension = ({}) => {
  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  
  const breadCrumbData = useBreadCrumbData();
  const { data } = useQuery('nextCode', async () => (await axios.get<{next_code: string}>(`/tenant/dimensions/next_code.json`)).data);
  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.post('/tenant/dimensions.json', values)
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    },
    onSuccess: () => {
      location.replace('/tenant/dimensions')
    }
  })
  
  useEffect(() => {
    if(data) {
      form.setFieldValue('code', data?.next_code);
    }  
  }, [data])

  return (
    <QPageLayout messageHolder={contextHolder} title={t('activerecord.attributes.dimension.new')} actionBtns={
      <BackButton />
    } breadCrumbData={breadCrumbData} >
      <Form
        labelWrap
        form={form}
        name="code"
        onFinish={mutation.mutate}
        scrollToFirstError
        validateMessages={{
          required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
        }}
        {...formItemLayout}
      >
          <Form.Item
            name="code"
            label={t("activerecord.attributes.dimension.create_code.code")}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name_ar"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_ar")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item
            name="name_en"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_en")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item name="description" label={t("activerecord.attributes.dimension.create_code.description")}>
            <Input.TextArea maxLength={100} rows={4} />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { offset: 0 }, lg: { offset: 12 } }}>
            <Flex gap="small" justify='end'>
              <Button type="primary" disabled={mutation.isLoading || mutation.isSuccess ? true : false} htmlType="submit">
                {t("forms.buttons.save")}
              </Button>
              <Button danger href='/tenant/dimensions'>
                {t("forms.buttons.cancel")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
    </QPageLayout>
  )
}

export default NewDimension