//@ts-nocheck
import React, { useRef, useState } from 'react'
import { List, Flex, Typography, Radio, RadioChangeEvent } from 'antd';
import i18next from 'i18next';
import ConfigurationOptions from './ConfigurationOptions';
import type { DataType as DimensionType } from 'pages/tenant/dimensions/index';
import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { MessageInstance } from 'antd/es/message/interface';
import { HookAPI } from 'antd/es/modal/useModal';
import { useDimensionPermissions } from 'hooks/dimensions';

const { Text } = Typography;
const ListItem = ({ dimensionRecord, messageApi, modal }: { dimensionRecord: DimensionType, messageApi: MessageInstance, modal: HookAPI }) => {  
  const { code, name_en, name_ar, global, id } = dimensionRecord;
  const [value, setValue] = useState(Number(global));
  const { permissions } = useDimensionPermissions();
  const { t } = useTranslation();
  
  const radioValuesRef = useRef([0, 1]);
  const mutation = useMutation<any, any, {dimensionID: number, value: number}>({
    mutationFn: async ({dimensionID, value}) => {
      return (await axios.patch(`/tenant/dimensions/${dimensionID}`, { global: Boolean(value) })).data
    },
    onSuccess: (dimension) => {
      const { global, name_ar, name_en, code } = dimension;
      setValue(Number(global));
      messageApi.open({
        type: 'success',
        content: t('activerecord.attributes.dimension.configuration.confirm.success', { name:  `${code} - ${i18next.language === 'en'? name_en : name_ar}`})
      });
    },
    onError: (err: AxiosError) => {
      let errorMsg = t('forms.messages.error');
      if(err.response?.data?.hasOwnProperty('global_limit')) {
        errorMsg = t('activerecord.attributes.dimension.configuration.errors.limit')
      }
      
      messageApi.open({
        type: 'error',
        content: errorMsg
      });
    }
  })

  const handleRadioChange = (record: DimensionType, e: RadioChangeEvent) => {
    const value = e.target.value;
    if(radioValuesRef.current.includes(value)) {
      if(Boolean(value)) {
        modal.confirm({
          title: t('activerecord.attributes.dimension.configuration.confirm.title'),
          content: t('activerecord.attributes.dimension.configuration.confirm.content'),
          okText: t('forms.buttons.confirm'),
          cancelText: t('forms.buttons.cancel'),
          onOk: () => {
            mutation.mutate({dimensionID: record.id, value});
          }
        });
      } else {
        mutation.mutate({dimensionID: record.id, value});
      }
    }
  }
  
  return (
    <List.Item>
      <Flex vertical gap={16} style={{width: '100%'}}>
        <Flex justify='space-between'>
          <Text>{code} - {i18next.language === 'en'? name_en : name_ar}</Text>
          <Radio.Group defaultValue={Number(global)} value={value} onChange={handleRadioChange.bind(null, dimensionRecord)} disabled={Boolean(value) || !permissions?.write}>
            <Radio style={{fontWeight: 'normal'}} value={1}>{t('activerecord.attributes.dimension.configuration.global')}</Radio>
            <Radio style={{fontWeight: 'normal'}} value={0}>{t('activerecord.attributes.dimension.configuration.shortcut')}</Radio>
          </Radio.Group>
        </Flex>
        {!Boolean(value) && <ConfigurationOptions dimensionID={id} permissions={permissions}/>}
      </Flex>
    </List.Item>
  )
}

export default ListItem