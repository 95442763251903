import React from "react";
import { useTranslation } from "react-i18next";
import QPageLayout from "components/layout/QPageLayout";
import { Divider, Row, Spin } from "antd";
import ReferralHeader from "components/tenant/referrals/ReferralHeader";
import { useBreadCrumbData, useReferralData } from "hooks/referrals";
import CardList from "components/tenant/referrals/CardList";
import { generateCardData } from "helpers/referrals";

const Referral: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { rewardsCount, loading } = useReferralData();
  const breadCrumbData = useBreadCrumbData();
  const language = i18n.language;
  const cardsData = generateCardData(rewardsCount, t, language);

  return (
      <QPageLayout
        title={t("referrals.name")}
        actionBtns={[]}
        breadCrumbData={breadCrumbData}
      >
        {loading ? (
          <Row justify="center">
            <Spin size="large" />
          </Row>
        ) : (
      <Row justify="start" style={{ width: "100%" }}>
        <ReferralHeader />
        <Divider />
        <CardList cardsData={cardsData} />
        </Row>
        )}
      </QPageLayout>
  );
};

export default Referral;