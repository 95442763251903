//@ts-nocheck
import React, { useState } from 'react'
import { Form, Flex } from 'antd'
import Select from './select';
import { useTranslation } from 'react-i18next'

const Item = ({ idx }: { idx: number }) => {
  const [hidden, setHidden] = useState(true);

  const hideItem = (value) => {
    setHidden(value);
  }  
  
  return (
    <Form.Item
      hidden={hidden}
      style={{flex: 1}}
      name={`select-${idx}`}
      wrapperCol={{span: 24}}
    >
      <Select idx={idx} hideItem={hideItem} />
    </Form.Item>
  )
}

export default Item