//@ts-nocheck
import React, { useMemo } from "react";
import { GetProp, Typography, Table } from "antd";
import { TableProps } from "antd/lib";
import { fetchDocuments } from "api/recurring_transactions";
import { useQuery } from "react-query";
import { InvoiceData, SimpleBillData } from "types/app";
import { DocumentTypes } from "types/recurring_transactions";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "hooks/app";
import { formatAmountWithCurrency } from "helpers/app";

export type TableDateType = InvoiceData | SimpleBillData;

interface Props {
  documentType: DocumentTypes | null;
  searchParams: string;
  onChange: TableProps["onChange"];
  onSelect: GetProp<typeof Table<any>, "rowSelection">["onChange"];
  selectedKeys: number[];
  pageSize: number;
  currentPage: number;
}

const translationTerms = {
  columns: {
    reference: "activerecord.external_documents.bill.reference",
    customerName: "activerecord.attributes.contact_payment.customer_name",
    vendorName: "activerecord.attributes.contact_payment.vendor_name",
    issueDate: "activerecord.external_documents.invoice.issue_date",
    total: "activerecord.attributes.contact_payment.amount",
    balance: "activerecord.attributes.contact.balance",
    dueDate: "activerecord.attributes.invoice.due_date",
    status: "activerecord.attributes.invoice.status",
  },
};

//The Main Component
const DocumentsTable = ({
  documentType,
  searchParams,
  onChange,
  onSelect,
  selectedKeys,
  pageSize,
  currentPage,
}: Props) => {
  const { data, isLoading, isPreviousData, isFetching } = useQuery({
    queryKey: [documentType, searchParams],
    queryFn: () => fetchDocuments(documentType, searchParams),
    enabled: Boolean(documentType),
    keepPreviousData: true,
  });

  const columns = useColumns(documentType);

  const is1920Screen = useMediaQuery("3xl:(size>=1600)");

  const isXXLScreen = useMediaQuery("xxl:(size>=1400)");

  const isSmallScreen = useMediaQuery("(max-width:992px)");

  const dataSource = useMemo(() => {
    if (
      (documentType === "Invoices" || documentType === "Bills") &&
      data &&
      "documents" in data
    ) {
      return data.documents;
    } else if (
      documentType === "SimpleBills" &&
      data &&
      "simple_bills" in data
    ) {
      return data.simple_bills;
    }
  }, [data, documentType]);

  return (
    <Table
      size={isSmallScreen ? "small" : "middle"}
      rowKey={"id"}
      onChange={onChange}
      rowSelection={{
        onChange: onSelect,
        selectedRowKeys: selectedKeys,
        preserveSelectedRowKeys: true,
      }}
      loading={isLoading || (isPreviousData && isFetching)}
      columns={columns}
      dataSource={dataSource}
      scroll={{
        y: is1920Screen
          ? "41vh"
          : isXXLScreen
          ? "35vh"
          : isSmallScreen
          ? "20vh"
          : "31vh",
        x: isSmallScreen ? 1000 : null,
      }}
      pagination={{
        size: isSmallScreen ? "small" : "default",
        current: currentPage,
        total: data && data?.meta.total_pages * pageSize,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 15, 25, 50, 75, 100],
        position: ["bottomCenter"],
      }}
    />
  );
};

const useColumns = (
  documentType: DocumentTypes | null
): TableProps<TableDateType>["columns"] => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery("(max-width:991px)");

  const baseColumns: TableProps<TableDateType>["columns"] = [
    {
      dataIndex: "reference",
      title: t(translationTerms.columns.reference),
      sorter: true,
      fixed: isSmallScreen,
    },
    {
      dataIndex: ["contact", "name"],
      title:
        documentType === "Invoices"
          ? t(translationTerms.columns.customerName)
          : t(translationTerms.columns.vendorName),
      sorter: true,
    },
    {
      dataIndex: "issue_date",
      title: t(translationTerms.columns.issueDate),
      sorter: true,
    },
    {
      dataIndex: documentType === "SimpleBills" ? "total_amount" : "total",
      title: t(translationTerms.columns.total),
      render(value) {
        return value && formatAmountWithCurrency(value);
      },
      sorter: true,
    },
    {
      dataIndex:
        documentType === "SimpleBills" ? "remaining_amount" : "due_amount",
      title: t(translationTerms.columns.balance),
      render(value) {
        return value && formatAmountWithCurrency(value);
      },
      sorter: true,
    },
    {
      dataIndex: "status",
      title: t(translationTerms.columns.status),
      render(value) {
        return (
          <Typography.Text type={value === "Approved" ? "success" : "warning"}>
            {value}
          </Typography.Text>
        );
      },
      sorter: true,
    },
  ];
  if (documentType === "Invoices" || documentType === "Bills") {
    baseColumns.splice(3, 0, {
      dataIndex: "due_date",
      title: t(translationTerms.columns.dueDate),
      sorter: true,
    });
  }
  return baseColumns;
};

export default DocumentsTable;
