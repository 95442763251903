//@ts-nocheck
import React from 'react';
import {Layout, Space, Typography} from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import GAMap from 'tokens/GAMap.json';
import ReactGA from 'react-ga4';

const {Footer} = Layout;
const {Text, Link} = Typography;

const mobileFooter = new Map<string, any>([
  ['Styles', {
    flexDirection: 'column',
    copyrightRank: -1,
    height: 154
  }]
])

const pcFooter = new Map<string, any>([
  ['Styles', {
    flexDirection: 'row',
    copyrightRank: 0,
    height: 70
  }]
])

const footerLinksAR = new Map([
  ['termsAndConditions', '/ara/terms-and-conditions'],
  ['privacyPolicy', '/ara/privacy-policy'],
  ['helpCenter', '/ara/مركز-المساعدة']
])

const footerLinksEN = new Map([
  ['termsAndConditions', '/en/terms-and-conditions'],
  ['privacyPolicy', '/en/privacy-policy'],
  ['helpCenter', '/en/help-center']
])

const trackGAEvent = (action: string) => {
  ReactGA.event({
    category: GAMap.footer.CATEGORY,
    action: action
  });
}


const QFooter = ({broken}) => {
    const {t} = useTranslation();
    const footerDirection = (broken? mobileFooter.get('Styles') : pcFooter.get('Styles'))?.flexDirection
    const copyrightRank = (broken? mobileFooter.get('Styles') : pcFooter.get('Styles'))?.copyrightRank
    const footerLinks = i18next.language === 'en'? footerLinksEN : footerLinksAR;

    return (
        <Footer style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: 32, flexDirection: footerDirection, gap: 20, position: 'absolute', bottom: 0}}>
            <Space size='large' style={{display: 'flex'}}>
            <Text style={{wordBreak: 'unset'}}>{i18next.language === 'en'? 'EN' : 'العربية'}</Text>
            </Space>
            <Space size='large' style={{display: 'flex', order: copyrightRank}}>
            <Text style={{wordBreak: 'unset'}}>{t('activerecord.attributes.devise.qoyod')} &#169; {(new Date()).getFullYear()}</Text>
            </Space>
            <Space size='large' style={{display: 'flex'}}>
            <Link style={{wordBreak: 'unset'}} onClick={() => { trackGAEvent(GAMap.footer.termsAndConditions); }} href={footerLinks.get('termsAndConditions')}>{t('activerecord.attributes.home.terms_of_use')}</Link>
            <Link style={{wordBreak: 'unset'}} onClick={() => { trackGAEvent(GAMap.footer.privacyPolicy); }} href={footerLinks.get('privacyPolicy')}>{t('activerecord.attributes.home.privacy_policy')}</Link>
            <Link style={{wordBreak: 'unset'}} onClick={() => { trackGAEvent(GAMap.footer.FAQ); }} href={footerLinks.get('helpCenter')}>{t('activerecord.attributes.home.faq')}</Link>
            </Space>
        </Footer>
    )
}

export default QFooter