import axios from 'axios';
import { ReferralData } from 'types/referrals';

export const fetchReferralLink = async (): Promise<ReferralData> => {
  const response = await axios.get(`/tenant/referrals.json`);
    return {
      referral_url: response.data.referrals.referral_url,
      referrals_count: response.data.referrals.referrals_count
    };
};
