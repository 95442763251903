//@ts-nocheck
import React from "react";
import { Row, Col, Divider } from "antd";
import ReferralCard from "./ReferralCard";
import RewardCard from "./RewardCard";
import { CardData } from "types/referrals";
import { useTranslation } from "react-i18next";

const CardList: React.FC<{ cardsData: CardData[] }> = ({ cardsData }) => {
  const { t } = useTranslation();

  return (
  <Row style={{ width: "100%" }} justify="space-around" align="bottom">
    {cardsData.map((card, index) => (
      <React.Fragment key={card.id}>
        <Col style={{ alignContent: "center", marginInlineStart: "2em" , marginTop: "3em", display: "flex", flexDirection: "column" }} xs={24} sm={24} md={10} lg={10}>
        {card.showInput ? (
          <ReferralCard
            title={card.title}
            description={card.description as string}
            buttonText={t("referrals.index.referral_card.copy_link")}
          />
        ) : (
          <RewardCard
            title={card.title}
            description={card.description}
            rewardsCount={card.rewardsCount || 0}
            color={card.color || "#1890ff"}         
         />
          )}
        </Col>
        {index === 0 && (
          <Col xs={0} sm={0} md={1} lg={1} style={{ justifyContent: "center", alignItems: "center",  }}>
            <Divider
              type="vertical"
              style={{ height: "20em", borderLeftWidth: ".1em"}}
            />
          </Col>
        )}
      </React.Fragment>
    ))}
  </Row>
  )
};

export default CardList;
