import { CDStatuses, InvoiceData, Permissions, SimpleBillData } from "./app";
import { Dayjs } from "dayjs";

export interface RTPermissions {
  recurring_transactions: Permissions;
  invoices: Permissions;
  bills: Permissions;
  simple_bills: Permissions;
}

export type ScheduleStatus =
  | "success"
  | "overdue"
  | "failed"
  | "scheduled"
  | "skipped"
  | "in_progress";

export enum ScheduleType {
  UPCOMMING = "upcoming",
  PASSED = "passed",
}

export type SelectedDocumentStatus =
  | "Approved"
  | "Awaiting for approval"
  | "Draft";

export interface ScheduleData {
  id: number;
  reference: string;
  created_at: string;
  scheduled_run_date: string;
  status: ScheduleStatus;
  actual_run_date: null | string;
  failure_reason: null | string;
  documents:
    | {
        id: number;
        reference: string;
        created_at: string;
        issue_date: string;
        status: SelectedDocumentStatus;
      }[]
    | null;
}

export interface ScheduleApiResponse {
  schedules: ScheduleData[];
  meta: {
    total_pages: number;
  };
}

export interface RTSchedules {
  actual_run_date: null | string;
  ar_name: string;
  created_at: string;
  en_name: string;
  failure_reason: null | string;
  id: number;
  original_documents: {
    id: number;
    reference: string;
  }[];
  recurring_transaction_id: number;
  reference: string;
  scheduled_run_date: string;
  status: ScheduleStatus;
}

export interface RTSchedulesResponse {
  recurring_transaction_schedules: RTSchedules[];
  meta: {
    total_pages: number;
  };
}

export interface QueryOpts {
  page?: string;
  enabled?: boolean;
  per_page?: string;
}

export type TransactionStatus =
  | "draft"
  | "scheduled"
  | "inactive"
  | "active"
  | "failed"
  | "completed";

export type TransactionDocumentStatus =
  | "draft"
  | "approved"
  | "awaiting_approval";

export type TransactionDocumentType = "Invoice" | "Bill" | "SimpleBill";

export interface TransactionDocumentData {
  id: number;
  reference: string;
  status: SelectedDocumentStatus;
  issue_date: string;
  due_date: string;
  remaining_amount: string;
  total_amount: string;
  contact: string;
}

export type TransactionRepeatTerm =
  | "daily"
  | "weekly"
  | "monthly"
  | "quarterly"
  | "semi_annually";

export interface TransactionData {
  id: number;
  reference: string;
  en_name: string;
  ar_name: string;
  status: TransactionStatus;
  user_comments: string;
  description: string;
  document_status: TransactionDocumentStatus;
  document_type: TransactionDocumentType;
  documents_total_amount: string;
  start_date: string;
  end_date: string;
  end_type: "custom" | "runs_count";
  repeat_term: TransactionRepeatTerm;
  total_runs: number;
  remaining_runs: number;
  deleted_at: null | string;
  created_at: string;
  updated_at: string;
  next_run_date: null | string;
  last_run_date: null | string;
}

export interface TransactionApiResponse {
  recurring_transaction: TransactionData;
  documents: TransactionDocumentData[];
  pagination: {
    current_page: number;
    per_page: number;
    total_entries: number;
    total_pages: number;
    next_page: null | number;
    previous_page: null | number;
  };
}

export type DocumentTypes = "Bills" | "SimpleBills" | "Invoices";

export type DocumentsRespnse =
  | { documents: InvoiceData[]; meta: { total_pages: number } }
  | { simple_bills: SimpleBillData[]; meta: { total_pages: number } };

export interface SelectedDocument {
  id: number;
  reference: string;
  status: CDStatuses;
  date: string;
  amount: string;
  type: DocumentTypes;
}

export interface RecurringTransactionData {
  ar_name: string;
  description?: string;
  document_status: "approved" | "awaiting_approval" | "draft";
  document_type: "Bill" | "Invoice" | "SimpleBill";
  en_name: string;
  end_date: Dayjs;
  end_type: string;
  recurring_transaction_documents_attributes: {
    documentable_id: number;
  }[];
  reference: string;
  repeat_term: "daily" | "weekly" | "monthly" | "quarterly" | "semi_annually";
  start_date: Dayjs;
  status: "scheduled" | "draft";
  total_runs: number;
  user_comments?: string;
}

export type RTDocumentsTypes = "Bill" | "Invoice" | "Simple Bill";

export type RTTabs = "All" | RTDocumentsTypes;

export interface RTRecord {
  id: number;
  reference: string;
  en_name: string;
  ar_name: string;
  status:
    | "draft"
    | "scheduled"
    | "inactive"
    | "active"
    | "failed"
    | "completed";
  user_comments: string;
  description: string;
  document_status: "approved" | "awaiting_approval" | "draft";
  document_type: "Invoice" | "Bill" | "SimpleBill";
  documents_total_amount: string;
  start_date: string;
  end_date: string;
  end_type: "custom" | "runs_count";
  repeat_term: "daily" | "weekly" | "monthly" | "quarterly" | "semi_annually";
  total_runs: number;
  remaining_runs: number;
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

export interface Filter {
  attribute: string;
  predicate: string;
  value: string;
}

export interface Sort {
  attribute: string;
  direction: "desc" | "asc";
}

export interface Pagination {
  current_page: number;
  per_page: number;
  total_entries: number;
  total_pages: number;
  next_page: null | number;
  previous_page: null | number;
}

export interface RTIndexResponse {
  filters: Filter[];
  sorts: Sort[];
  records: RTRecord[];
  pagination: Pagination;
}

export type ToggleStatus = Extract<RTRecord["status"], "active" | "inactive">;
