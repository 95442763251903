//@ts-nocheck
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query"
import type { Permissions } from "types/app";

export const useDimensionPermissions = () => {
  const {data, error} = useQuery<Permissions>({
    queryKey: ['dimensionPermission'],
    queryFn: async () => {
      return (await axios.get('/tenant/dimensions/permissions.json')).data
    }
  });

  useEffect(() => {
    if(error) {
      window.location.replace('/tenant/dashboards');
    }
  }, [error])
  const permissions = data;
  return { permissions };
}