//@ts-nocheck
import BackButton from "components/layout/BackButton";
import QPageLayout from "components/layout/QPageLayout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Typography, Flex, theme, Tooltip,Badge } from "antd";
import {
  CodeSandboxOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import BudgetCard from "components/tenant/budgets/BudgetCard";
import AccountBudgeting from "components/tenant/budgets/AccountBudgeting";
import ItemBudgeting from "components/tenant/budgets/ItemBudgeting";
const { useToken } = theme;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t("dashboard.quick_actions.accounting_header"),
    },
    {
      title: <a href="/tenant/budgets">{t("budgets.name")}</a>,
    },
    {
      title: t("budgets.select_budget"),
    },
  ];
};

const New = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const breadCrumbData = useBreadCrumbData();
  const { Text } = Typography;
  type BudgetType = {
    id: number;
    name: string;
    icon: any;
    text: string;
    description: string;
    disabled?: boolean;
  };
  const [budgetTypes, setBudgetTypes] = useState<BudgetType[]>([
    {
      id: 2,
      name: "accounts",
      icon: <CodeSandboxOutlined style={{ fontSize: "40px" }} />,
      text: t("budgets.accounts_budgeting.name"),
      description: t("budgets.accounts_budgeting.description"),
    },
    {
      id: 1,
      name: "items",
      icon: <CopyOutlined style={{ fontSize: "40px" }} />,
      text: t("budgets.item_budgeting.name"),
      description: t("budgets.item_budgeting.description"),
      disabled: true,
    },
  ]);

  const [selectedBudget, setSelectedBudget] = useState<string | null>(null);

  const headerStyle = {
    color: token.colorPrimary,
    fontSize: "24px",
  };

  return (
    <QPageLayout
      title={t("budgets.new")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
    >
      <>
        {!selectedBudget ? (
          <Flex
            gap={"64px"}
            style={{ minHeight: "60vh" }}
            justify="center"
            align="center"
            vertical
          >
            <Text style={headerStyle}>{t("budgets.select_budget")}</Text>
            <Flex justify="center" align="center" gap={"42px"} wrap={"wrap"}>
              {budgetTypes.map((el, i) => {
                return (
                  <Tooltip placement="bottom"  title={el.description} key={el.id}>
                    <Flex
                      style={{
                        cursor: el.disabled ? "not-allowed" : "pointer",
                        position: "relative",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (!el.disabled) {
                      setSelectedBudget(el.name);}
                    }}
                  >
                    {el.disabled ? (
                      <Badge.Ribbon text={t("budgets.status.soon")} color="blue">
                        <Flex
                          style={{
                            opacity: 0.5,
                          }}
                        >
                          <BudgetCard text={el.text} icon={el.icon}></BudgetCard>
                        </Flex>
                      </Badge.Ribbon>
                    ) : (
                    <BudgetCard text={el.text} icon={el.icon}></BudgetCard>
                    )}
                  </Flex>
                  </Tooltip>
                );
              })}
            </Flex>
          </Flex>
        ) : selectedBudget == "accounts" ? (
          <AccountBudgeting />
        ) : (
          <ItemBudgeting />
        )}
      </>
    </QPageLayout>
  );
};

export default New;
