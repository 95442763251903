//@ts-nocheck
import React from 'react'
import Portal from 'components/layout/Portal'
import { useTranslation } from 'react-i18next'
import { theme } from 'antd';

const {useToken} = theme;

const IndexPortal = ({}) => {    
  const { t } = useTranslation();
  const { token } = useToken();
  return (
    <>
        <Portal Component={<div></div>} htmlRoot='portal__element_name' />
    </>
  )
}

export default IndexPortal