//@ts-nocheck
import {
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Flex, Space } from "antd";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const BudgetActionButton = ({ permissions }) => {  
  const { t } = useTranslation();
  return (
    <Flex vertical gap={10} align="end">
      <Space>
        {permissions['write'] && (
          <Button
            icon={
              i18next.language === "en" ? <PlusOutlined /> : <PlusOutlined />
            }
            type="primary"
            href="/tenant/budgets/new"
          >
            {t("budgets.index.create_new_budget")}
          </Button>
        )}
      </Space>
    </Flex>
  );
};

export default BudgetActionButton;
