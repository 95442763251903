//@ts-nocheck
import React from 'react';
import { Layout, theme, Space } from 'antd';

const { Content } = Layout;
const {useToken} = theme;

const QContent: React.FC<{filter: JSX.Element, children: React.ReactNode | React.ReactNode[]}> = ({ children, filter }) => {

    const { token } = useToken();
    
    return (
        <>
            <div style={{ borderTop: "4px solid #217CA8", paddingInline: token.paddingMD, paddingBlock: token.paddingSM, backgroundColor: token.colorPrimary}}>
                {filter}
            </div>
            <Content
                style={{
                    paddingInline: token.paddingMD,
                    paddingTop: token.paddingLG,
                    paddingBottom: 2*token.paddingMD,
                    backgroundColor: token.colorBgContainer,
                    margin: 0,
                    minHeight: 280
                }}
            >
                <Space style={{display: 'flex'}} direction="vertical" size={token.paddingLG}>
                    {children}
                </Space>
            </Content>
        </>
        
    )
}



export default QContent;