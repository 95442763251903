//@ts-nocheck
import React from "react";
import { Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { TransactionStatus } from "types/recurring_transactions";
import { useStatusMap } from "hooks/status";

export default function Status({
  status,
  renderAsTag = false,
}: {
  status: TransactionStatus;
  renderAsTag?: boolean;
}) {
  const { t } = useTranslation();

  const statuses = useStatusMap();

  const normalizedStatus = statuses._normalize(status);

  const color = statuses[normalizedStatus]?.color;

  const translatedStatus = t(statuses[normalizedStatus]?.label);

  return renderAsTag ? (
    <Tag color={color}>{translatedStatus}</Tag>
  ) : (
    <Typography.Text strong style={{ color }}>
      {translatedStatus}
    </Typography.Text>
  );
}
