//@ts-nocheck
import React from "react";
import { ConfigProvider, Table, Tabs, theme, Typography } from "antd";
import { TableProps } from "antd/lib";
import { useTranslation } from "react-i18next";
import { TransactionDocumentData } from "types/recurring_transactions";
import { useStatusMap } from "hooks/status";
import { useMediaQuery } from "hooks/app";

interface Props {
  documents: TransactionDocumentData[];
  documentType: "Invoice" | "Bill" | "SimpleBill";
  onChange: TableProps["onChange"];
  totalDocs: number | undefined;
  pageSize: number | undefined;
  isLoading: boolean;
}

const translationTerms = {
  title: "recurring_transactions.show.related_documents",
  columns: {
    reference: "activerecord.external_documents.bill.reference",
    customerName: "activerecord.attributes.contact_payment.customer_name",
    vendorName: "activerecord.attributes.contact_payment.vendor_name",
    issueDate: "activerecord.external_documents.invoice.issue_date",
    total: "activerecord.attributes.contact_payment.amount",
    balance: "activerecord.attributes.contact.balance",
    dueDate: "activerecord.attributes.invoice.due_date",
    status: "activerecord.attributes.invoice.status",
  },
};

export default function RelatedDocs({
  documents,
  documentType,
  onChange,
  totalDocs,
  pageSize,
  isLoading,
}: Props) {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  const columns = useColumns(documentType);

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: { margin: 0, cardBg: token.colorPrimaryBg, borderRadiusLG: 0 },
          Table: { headerBorderRadius: 0, headerBg: token.colorPrimaryBg },
        },
      }}
    >
      <Typography.Title level={4} style={{ marginBottom: token.marginLG }}>
        {t(translationTerms.title)}
      </Typography.Title>
      <Tabs
        size="large"
        type="card"
        activeKey=""
        items={[
          {
            label: <Typography.Text strong>{documentType}</Typography.Text>,
            key: "documentType",
          },
        ]}
      />
      <Table
        scroll={{ x: isLargeScreen ? 0 : 400 }}
        rowKey={"reference"}
        loading={isLoading}
        pagination={{ total: totalDocs, pageSize, hideOnSinglePage: true }}
        onChange={onChange}
        dataSource={documents}
        columns={columns}
      />
    </ConfigProvider>
  );
}

const useColumns = (
  documentType: "Invoice" | "Bill" | "SimpleBill"
): TableProps<TransactionDocumentData>["columns"] => {
  const { t } = useTranslation();

  const status = useStatusMap();

  const documentTypeKey =
    documentType &&
    (documentType === "Bill" || documentType === "Invoice"
      ? documentType.toLocaleLowerCase() + "s"
      : "simple_bills");

  return [
    {
      dataIndex: "reference",
      title: t(translationTerms.columns.reference),
      render(value, record) {
        return (
          <Typography.Link
            target="_blank"
            href={`/tenant/${documentTypeKey}/${record.id}`}
          >
            {value}
          </Typography.Link>
        );
      },
    },
    {
      dataIndex: "contact",
      title: t(
        translationTerms.columns[
          documentType === "Invoice" ? "customerName" : "vendorName"
        ]
      ),
    },
    {
      dataIndex: "issue_date",
      title: t(translationTerms.columns.issueDate),
    },
    {
      dataIndex: "due_date",
      title: t(translationTerms.columns.dueDate),
    },
    {
      dataIndex: "total_amount",
      title: t(translationTerms.columns.total),
    },
    {
      dataIndex: "status",
      title: t(translationTerms.columns.status),
      render(value) {
        return (
          <Typography.Text style={{ color: status[value]?.color }}>
            {status[value]?.label}
          </Typography.Text>
        );
      },
    },
  ];
};
