import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Link from "antd/es/typography/Link";
import QPageLayout from "components/layout/QPageLayout";
import NoRecurringTransactions from "components/tenant/recurring_transactions/index/NoRecurringTransactions";
import RecordsFilter from "components/tenant/recurring_transactions/index/RecordsFilter";
import RecordsTable from "components/tenant/recurring_transactions/index/RecordsTable";
import IndexTabs from "components/tenant/recurring_transactions/index/Tabs";
import { useInteractiveFilter } from "hooks/app";
import { useRTPermissions, useRTRecords } from "hooks/recurring_transactions";

const RecurringTransactions = () => {
  const { t } = useTranslation();

  const pageTitle = t("recurring_transactions.title");

  const [hasFilterUpdated, setHasFilterUpdated] = useState(false);

  const { isPermissionsLoading, permissions } = useRTPermissions();

  const canWrite: boolean =
    !isPermissionsLoading && (permissions?.recurring_transactions?.write || false);

  const interactiveFilter = useInteractiveFilter(
    {
      setFilterUpdate: setHasFilterUpdated,
      path: "",
    },
    [hasFilterUpdated]
  );

  const { data } = useRTRecords({
    queryKey: ["RTIndex", interactiveFilter.url],
    querySearch: interactiveFilter.url,
    keepPreviousData: true,
  });

  return (
    <QPageLayout
      title={pageTitle}
      breadCrumbData={[
        {
          title: <Link href="#">{t("activerecord.attributes.layout.accounting")}</Link>,
        },
        { title: pageTitle },
      ]}
      actionBtns={
        <Button type="primary" href="recurring_transactions/new" disabled={!canWrite}>
          <PlusOutlined /> {t("recurring_transactions.create.button")}
        </Button>
      }
    >
      {data?.records.length === 0 && data?.filters.length === 0 ? (
        <NoRecurringTransactions />
      ) : (
        <>
          <IndexTabs
            form={interactiveFilter.form}
            page={interactiveFilter.page}
            update={interactiveFilter.update}
          />
          <RecordsFilter {...interactiveFilter} />
          <RecordsTable {...interactiveFilter} />
        </>
      )}
    </QPageLayout>
  );
};

export default RecurringTransactions;
