//@ts-nocheck
import React from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const BackButton = () => {

  const { t } = useTranslation();
    
  return (
    <Button icon={i18next.language === 'en'? <ArrowLeftOutlined /> : <ArrowRightOutlined />} type="primary" onClick={window.go_back}>{t('forms.buttons.back')}</Button>
  )
}

export default BackButton;