//@ts-nocheck
import React from "react";
import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  theme,
  TimePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import { CustomFiledType } from "types/app";
import dayjs from "dayjs";

const translationTerms = {
  from: "forms.placeholders.from",
  to: "forms.placeholders.to",
};

const CustomField = ({
  type,
  customFieldTypeInputName = "q[custom_field_values_cpn_custom_field_id_eq]",
}: {
  type: CustomFiledType;
  customFieldTypeInputName?: string;
}) => {
  const { t } = useTranslation();

  const { token } = theme.useToken();

  let content: React.ReactNode;

  switch (type) {
    case "Boolean":
      content = (
        <Form.Item
          getValueProps={(value) => ({
            checked: value === "on" ? true : false,
          })}
          initialValue={"off"}
          normalize={(value) => (value ? "on" : "off")}
          valuePropName="checked"
          name={"q[custom_field_values_value_cont]"}
        >
          <Checkbox />
        </Form.Item>
      );
      break;
    case "Integer":
    case "Decimal":
      content = (
        <Form.Item name={"q[custom_field_values_value_cont]"}>
          <InputNumber />
        </Form.Item>
      );
      break;
    case "Text":
      content = (
        <Form.Item name={"q[custom_field_values_value_cont]"}>
          <Input />
        </Form.Item>
      );
      break;
    case "Date":
      content = (
        <Flex gap={token.marginSM}>
          <Form.Item
            getValueProps={(value) => ({
              value: value && dayjs(value),
            })}
            normalize={(value) =>
              value && `${dayjs(value).format("YYYY-MM-DD")}`
            }
            name="q[custom_field_values_value_gteq]"
          >
            <DatePicker placeholder={t(translationTerms["from"])} />
          </Form.Item>
          <Form.Item
            getValueProps={(value) => ({
              value: value && dayjs(value),
            })}
            normalize={(value) =>
              value && `${dayjs(value).format("YYYY-MM-DD")}`
            }
            name="q[custom_field_values_value_lteq]"
          >
            <DatePicker placeholder={t(translationTerms["to"])} />
          </Form.Item>
        </Flex>
      );
      break;
    case "Time":
      content = (
        <Flex gap={token.marginSM}>
          <Form.Item
            getValueProps={(value) => ({
              value: value && dayjs(value, "HH:mm"),
            })}
            normalize={(value) => value && `${dayjs(value).format("HH:mm")}`}
            name="q[custom_field_values_value_gteq]"
          >
            <TimePicker placeholder={t(translationTerms["from"])} />
          </Form.Item>
          <Form.Item
            getValueProps={(value) => ({
              value: value && dayjs(value, "HH:mm"),
            })}
            normalize={(value) => value && `${dayjs(value).format("HH:mm")}`}
            name="q[custom_field_values_value_lteq]"
          >
            <TimePicker placeholder={t(translationTerms["to"])} />
          </Form.Item>
        </Flex>
      );
      break;
  }

  return (
    <Form.Item
      shouldUpdate={(prevValues, nextValues) =>
        prevValues[customFieldTypeInputName] !==
        nextValues[customFieldTypeInputName]
      }
      noStyle
    >
      {({ getFieldValue }) =>
        getFieldValue(customFieldTypeInputName)
          ? content
          : null
      }
    </Form.Item>
  );
};

export default CustomField;
