//@ts-nocheck
import React, { useEffect } from "react";
import { useInteractiveFilter } from "hooks/app";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  theme,
} from "antd";
import { useRTRecords } from "hooks/recurring_transactions";
import { useTranslation } from "react-i18next";
import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

type Props = Pick<
  ReturnType<typeof useInteractiveFilter>,
  | "pageSize"
  | "page"
  | "update"
  | "url"
  | "form"
  | "sorter"
  | "setFormContainerRef"
>;

const translationTerms = {
  reference: "activerecord.external_documents.bill.reference",
  total: "activerecord.attributes.contact_payment.amount",
  status: "activerecord.attributes.invoice.status",
  name: "activerecord.attributes.invoice.name",
  recurringType: "recurring_transactions.index.recurring_type",
  nextDate: "recurring_transactions.index.next_date",
  actions: "recurring_transactions.index.actions",
  type: {
    daily: "recurring_transactions.index.daily",
    weekly: "recurring_transactions.index.weekly",
    monthly: "recurring_transactions.index.monthly",
    quarterly: "recurring_transactions.index.quarterly",
    semi_annually: "recurring_transactions.index.semi_annually",
  },
  statuses: {
    active: "recurring_transactions.index.statuses.active",
    draft: "recurring_transactions.index.statuses.draft",
    inactive: "recurring_transactions.index.statuses.inactive",
    scheduled: "recurring_transactions.index.statuses.scheduled",
    failed: "recurring_transactions.index.statuses.failed",
    completed: "recurring_transactions.index.statuses.completed",
  },
  filter: "forms.buttons.filter",
  reset: "forms.buttons.reset",
};

const RecordsFilter = ({
  setFormContainerRef,
  form,
  update,
  url,
  sorter,
}: Props) => {
  const { data } = useRTRecords({
    queryKey: ["RTIndex", url],
    querySearch: url,
  });

  const { t, i18n } = useTranslation();

  const { token } = theme.useToken();

  const searchParams = new URLSearchParams(window.location.search);

  // Apply persisted filters only when no search queries are present
  const shouldApplyStoredFilter = !searchParams.size;

  const resetFilterHandler = () => {
    form.resetFields();
    sorter.reset();
    update();
  };

  useEffect(() => {
    if (data?.filters && shouldApplyStoredFilter) {
      data.filters.forEach((filter) => {
        form.setFieldValue(
          `q[${filter.attribute}_${filter.predicate}]`,
          filter.value ?? undefined
        );
      });
    }
  }, [data?.filters, shouldApplyStoredFilter]);

  return (
    // The interactive filter hook should not run until the persisted filters are applied to avoid triggering an extra request.
    <div ref={data ? setFormContainerRef : null}>
      <Form form={form}>
        <Row wrap gutter={[token.margin, token.marginMD]}>
          <Form.Item noStyle name={"q[document_type_eq]"}></Form.Item>
          <Form.Item noStyle name={"reset"} initialValue={"true"}></Form.Item>
          <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item name={"q[reference_eq]"}>
              <Input placeholder={t(translationTerms["reference"])} />
            </Form.Item>
          </Col>

          <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item name={"q[documents_total_amount_eq]"}>
              <InputNumber
                style={{ width: "100%" }}
                placeholder={t(translationTerms["total"])}
              />
            </Form.Item>
          </Col>

          <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item
              name={`q[${i18n.language === "ar" ? "ar_name" : "en_name"}_cont]`}
            >
              <Input placeholder={t(translationTerms["name"])} />
            </Form.Item>
          </Col>

          <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item
              name={"q[repeat_term_eq]"}
              getValueProps={(v) => ({
                value: v === "" ? undefined : v,
              })}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder={t(translationTerms["recurringType"])}
                options={Object.entries(translationTerms.type).map(
                  ([key, value]) => ({
                    value: key,
                    label: t(value),
                  })
                )}
              />
            </Form.Item>
          </Col>

          <Col span={12} lg={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item
              getValueProps={(value) => ({
                value: value && dayjs(value),
              })}
              normalize={(value) =>
                value && `${dayjs(value).format("YYYY-MM-DD")}`
              }
              name={"q[start_date_eq]"}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t(translationTerms["nextDate"])}
              />
            </Form.Item>
          </Col>

          <Col span={12} lg={{ span: 8 }} xl={{ span: 4, offset: 4, pull: 4 }}>
            <Form.Item
              name={"q[status_eq]"}
              getValueProps={(v) => ({
                value: v === "" ? undefined : v,
              })}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                placeholder={t(translationTerms["status"])}
                options={Object.entries(translationTerms.statuses).map(
                  ([key, value]) => ({
                    value: key,
                    label: t(value),
                  })
                )}
              />
            </Form.Item>
          </Col>

          <Col xl={{pull:4}}>
            <FilterActions
              update={update}
              resetFilterHandler={resetFilterHandler}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const FilterActions = ({
  update,
  resetFilterHandler,
}: Pick<Props, "update"> & { resetFilterHandler: () => void }) => {
  const { token } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Flex gap={token.marginSM}>
      <Button type="primary" icon={<FilterOutlined />} onClick={update}>
        {t(translationTerms.filter)}
      </Button>
      <Button icon={<ReloadOutlined />} onClick={resetFilterHandler}>
        {t(translationTerms.reset)}
      </Button>
    </Flex>
  );
};

export default RecordsFilter;
