//@ts-nocheck
import React, { ReactNode } from "react";
import { Col, Result, Row } from "antd";

interface QOnBoardingIndexProps {
  icon: React.ReactElement;
  title: string;
  subtitle: string;
  extra: ReactNode;
  iconSize?: number;
}

const QOnBoardingIndex = ({
  icon,
  title,
  subtitle,
  extra,
  iconSize = 72,
}: QOnBoardingIndexProps) => {
  const iconWithStyle = React.cloneElement(icon, { style: { fontSize: iconSize } });

  return (
    <Row justify="center" align="middle">
      <Col lg={22} xl={16} xxl={11}>
        <Result icon={iconWithStyle} title={title} subTitle={subtitle} extra={extra} />
      </Col>
    </Row>
  );
};

export default QOnBoardingIndex;
