//@ts-nocheck
import React from 'react'

import { Breadcrumb, theme } from 'antd'
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'

const { useToken } = theme;

interface QPageHeaderProps {
  children: React.ReactNode | React.ReactNode[];
  breadCrumbData: ItemType[];
}

const QPageHeader: React.FC<QPageHeaderProps> = ({ children, breadCrumbData }) => {

  const { token } = useToken();
  
  return (
    <>
      <Breadcrumb
          style={{paddingTop: token.paddingLG}}
          items={breadCrumbData}
      />
      <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: token.paddingMD}}>
          {children}
      </div>
    </>
  )
}

export default QPageHeader