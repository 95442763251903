//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DatePicker, Row, Col, Select, Flex, Form, Button, Input, ConfigProvider, TableProps, Table, Dropdown, Space, MenuProps } from 'antd'
import { useTranslation } from 'react-i18next'
import QReportLayout from 'components/layout/QReportLayout';
import { useQuery } from 'react-query';
import { DimensionResponse, ReportSchema, SchemaItem } from 'types/dimensions';
import i18next from 'i18next';
import { useInteractiveFilter } from 'hooks/app';
import { DownOutlined, FileExcelOutlined, FilePdfOutlined, FilterOutlined, UndoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { v4 as generateUUID } from 'uuid'

const { RangePicker } = DatePicker;

const useOptions = () => {
  const { t } = useTranslation();
  const axisOptions = useMemo(() => ([
    {value: 'dimensions', label: t('activerecord.attributes.tenant_role.dimensions')},
    {value: 'products', label: t('activerecord.attributes.layout.products')},
    {value: 'inventories', label: t('templates.reports.locations')},
    {value: 'projects', label: t('templates.reports.projects')},
    {value: 'period', label: t('activerecord.attributes.payslip.period')}
  ]), [])
  
  const analysisByOptions = useMemo(() => ([
    {value: 'purchases', label: t('activerecord.attributes.inventory_report.purchases')},
  ]), [])

  const asValueOptions = useMemo(() => ([
    { value: 'Amount', label: t('templates.reports.amount') },
    { value: 'Quantity', label: t('templates.quantity') }
  ]), [])

  return { axisOptions, analysisByOptions, asValueOptions };
}



export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: <a href="/tenant/reports/dashboard">{t('activerecord.attributes.breadcrums.reports')}</a>,
    },
    {
      title: t('reports.analysis_by_dimensions.title'),
    },
  ]
}

const DimensionField = ({ fieldName }: { fieldName: string }) => {
  const [dimensionOptions, setDimensionOptions] = useState([]);
  const dimensionQuery = useQuery<DimensionResponse>(['dimensionValues'], async () => {
    return (await axios.get(`/tenant/dimensions.json?per_page=100`)).data
  })

  useEffect(() => {
    if(dimensionQuery.data) {
      const { data } = dimensionQuery;
      const readyData = data.records.map(record => ({ label: `${record.code} - ${i18next.language === 'en'? record.name_en : record.name_ar}`, value: String(record.id) }));
      setDimensionOptions(readyData);
    }
  }, [ dimensionQuery.data ])  
  
  return (
    <Form.Item name={fieldName} rules={[{required: true}]}>
      <Select
        style={{width: '100%'}}
        options={dimensionOptions}
        loading={dimensionQuery.isLoading}
        allowClear={true}
      />
    </Form.Item>
  )

}

const DateField = ({ fieldName }: { fieldName: string }) => {
  const form = Form.useFormInstance();
  const startDateValue = Form.useWatch(`${fieldName}[start_date]`, form);
  const endDateValue = Form.useWatch(`${fieldName}[end_date]`, form);
  
  return (
    <>
      <RangePicker 
        style={{width: '100%'}}
        onChange={(_, val) => {form.setFieldValue(`${fieldName}[start_date]`, val[0]); form.setFieldValue(`${fieldName}[end_date]`, val[1])}}
        value={[dayjs(startDateValue), dayjs(endDateValue)]}
        allowClear={false}
      />
      <Form.Item initialValue={dayjs().format('YYYY-MM-DD')} hidden={true} name={`${fieldName}[start_date]`}>
        <Input />
      </Form.Item>
      <Form.Item initialValue={dayjs().format('YYYY-MM-DD')} hidden={true} name={`${fieldName}[end_date]`}>
        <Input />
      </Form.Item>
    </>
  )

}

const ExtraField = ({ axisValue, axisLabel }: { axisValue: string, axisLabel: string }) => {  
  let field = null;
  let fieldCol = 4;

  switch(axisValue) {
    case 'dimensions':
      field = (
        <DimensionField fieldName={`filter_options[${axisLabel}][dimension_id]`} />
      );
      break;
    case 'period':
      fieldCol = 8;
      field = (
        <DateField fieldName={`filter_options[${axisLabel}]`} />
      );
      break;
  }
  
  if(field) {
    return (
      <Col xs={24} md={8} lg={fieldCol}>
        {field}
      </Col>
    )
  }

  return field;
}


const Filter = ({ InteractiveFilter }: { InteractiveFilter: ReturnType<typeof useInteractiveFilter> }) => {
  const { t } = useTranslation();
  const { update, setFormContainerRef, form } = InteractiveFilter;

  const rowValue = Form.useWatch('as_rows', form)
  const columnValue = Form.useWatch('as_columns', form)
  const { axisOptions, asValueOptions, analysisByOptions } = useOptions();

  const handleFilterSubmit = useCallback(() => {
    update();
  }, [])

  const handleFilterReset = useCallback(() => {
    form.resetFields();
    update();
  }, [])

  return (
    <div ref={setFormContainerRef}>
      <ConfigProvider theme={{components: { Form: { itemMarginBottom: 0 } }}}>
        <Form
          form={form}
          onFinish={handleFilterSubmit}
          wrapperCol={{span: 24, flex: 1}}
          validateMessages={{
            required: t("forms.placeholders.field_required")
          }}
        >
          <Flex vertical gap={15} style={{ width: '100%' }}>
            <Row gutter={[15, 15]}>
              <Col xs={24} md={8} lg={4}>
                <Form.Item rules={[{required: true}]} name="analysis_by">
                  <Select
                    style={{width: '100%'}}
                    placeholder={t('reports.analysis_by_dimensions.analysis_by')}
                    options={analysisByOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={4}>
                <Form.Item rules={[{required: true}]} name="as_rows">
                  <Select
                    style={{width: '100%'}}
                    placeholder={t('reports.analysis_by_dimensions.show_rows')}
                    options={axisOptions}
                  />
                </Form.Item>
              </Col>
              <ExtraField axisValue={rowValue} axisLabel="rows" />
            </Row>
            <Row gutter={[15, 15]}>
              <Col xs={24} md={8} lg={4}>
                <Form.Item rules={[{required: true}]} name="as_value">
                  <Select
                    style={{width: '100%'}}
                    placeholder={t('reports.analysis_by_dimensions.show_value')}
                    options={asValueOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={4}>
                <Form.Item rules={[{required: true}]} name="as_columns">
                  <Select
                    style={{width: '100%'}}
                    placeholder={t('reports.analysis_by_dimensions.show_columns')}
                    options={axisOptions}
                  />
                </Form.Item>
              </Col>
              <ExtraField axisValue={columnValue} axisLabel="columns" />
            </Row>
            <Row gutter={[15, 15]}>
              <Col xs={12} md={{offset: 8, span: 8}} lg={{offset: 18, span: 3}}>
                <Form.Item>
                  <Button style={{ width: '100%' }} icon={<FilterOutlined />} htmlType='submit' type='default'>{t('forms.buttons.filter')}</Button>
                </Form.Item>
              </Col>
              <Col xs={12} md={8} lg={{span: 3}}>
                <Form.Item>
                  <Button style={{ width: '100%' }} onClick={handleFilterReset} icon={<UndoOutlined />} danger>{t('forms.buttons.reset')}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Flex>
        </Form>
      </ConfigProvider>
    </div>
  )
}

type AggregateColumnType<T> = ColumnGroupType<T> | ColumnType<T>

const buildColumns = (key: string, item: SchemaItem): AggregateColumnType<Record<string, string>> => {  
  const children: AggregateColumnType<Record<string, string>>[] = [];
  for(const [key, value] of Object.entries(item?.children ?? {})) {
    children.push(buildColumns(key, value))
  }

  return {
    dataIndex: key,
    title: item.key,
    align: 'center',
    children,
    width: 150,
    render: (text) => text === null ? '0.00' : isNaN(parseFloat(text)) ? text : parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
}


const useColumns = ({ schema }: { schema: ReportSchema }) => {
  
  const columns = useMemo<TableProps['columns']>(() => {
    if(!schema || !Object.keys(schema).length) return [];    
    const colSchema = Object.entries(schema).map(([key, value]) => buildColumns(key, value));
    colSchema[0].rowScope = 'row';
    colSchema[0].fixed = true;
    return colSchema;
  }, [ schema ])

  return columns;
}

const DimensionReport = ({ }) => {
  const { t } = useTranslation();
  const [filterUpdate, setFilterUpdate] = useState(false);  
  
  const breadCrumbData = useBreadCrumbData();

  const interactiveFilter = useInteractiveFilter({
    path: '/tenant/reports/analysis_by_dimensions.json',
    setFilterUpdate
  }, [filterUpdate]);
  
  const { data, isLoading } = useQuery<{schema: ReportSchema, records: Record<string, string>[]}>(['reportData', interactiveFilter.url], async () => {
    const data = (await axios.get(interactiveFilter.url)).data
    data.records?.forEach(record => {
      record.key = generateUUID();
    })
    return data;
  });
  const columns = useColumns({ schema: data?.schema })

  const items: MenuProps['items'] = useMemo(
    () => {
        const currentUrl = new URL(window.location.href);
        const currentQuery = new URL(interactiveFilter.url, window.location.origin).search;
        
        const pdfExportUrl = `${currentUrl.pathname}.pdf${currentQuery}`;
        const excelExportUrl = `${currentUrl.pathname}.xlsx${currentQuery}`;
        return ([
          {
            label: (
              <a href={pdfExportUrl} target="_blank" rel="noopener noreferrer">
                {t('forms.buttons.pdf')}
              </a>
            ),
            key: '1',
            icon: <FilePdfOutlined style={{ color: 'red' }} />,
          },
          {
            label: (
              <a href={excelExportUrl} target="_blank" rel="noopener noreferrer">
                {t('forms.buttons.excel')}
              </a>
            ),
            key: '2',
            icon: <FileExcelOutlined style={{ color: 'green' }} />,
          },
        ])
    }, [ interactiveFilter.url ]
  );

  const menuProps = {
    items,
  };
  
  return (
    <QReportLayout filter={<Filter InteractiveFilter={interactiveFilter} />} title={t('reports.analysis_by_dimensions.title')} actionBtns={
      <></>
    } breadCrumbData={breadCrumbData} >
      <Table bordered scroll={{ x: '100%', y: 500 }} pagination={false} columns={columns} loading={isLoading} virtual={true} dataSource={data?.records ?? []} />
      <Flex vertical align="end">
        <Dropdown menu={menuProps}>
          <Button type="primary">
            <Space>
              {t('forms.buttons.export')} 
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Flex>
    </QReportLayout>
  )
}


export default DimensionReport