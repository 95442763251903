//@ts-nocheck
import React, { useEffect } from 'react'

import { theme, Layout } from 'antd';
import QPageHeader from './QPageHeader';
import QReportContent from './QReportContent';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import styles from 'styles/layout.scss';

const { useToken } = theme;

interface QPageLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  breadCrumbData: ItemType[]; 
  actionBtns: React.ReactNode | React.ReactNode[];
  title: string;
  filter: JSX.Element;
  messageHolder?: React.ReactNode
}

const QPageLayout: React.FC<QPageLayoutProps> = ({children, breadCrumbData, actionBtns, title, filter, messageHolder}) => {

  const { token } = useToken();

  useEffect(()=> {
    document.title = title;
  }, [title])
  
  return (
    <Layout style={{ paddingInline: token.paddingLG }} className={styles.q_ant_input__radio}>
      {messageHolder}
      <QPageHeader breadCrumbData={breadCrumbData} >
        {actionBtns}
      </QPageHeader>
      <QReportContent filter={filter}>
        {children}
      </QReportContent>
    </Layout>
)
}

export default QPageLayout
