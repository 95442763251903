//@ts-nocheck
import React from "react";
import { Form, Space, Input, Select, Button, DatePicker, Row } from "antd";
import { FilterOutlined, RedoOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const { Option } = Select;

const FilterForm = ({ onSubmit, onReset }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleFilterSubmit = () => {
    form.validateFields().then((values) => {
      onSubmit({
        ...values,
        "q[fiscal_year_eq]":
          values["q[fiscal_year_eq]"] &&
          dayjs(values["q[fiscal_year_eq]"])
            .startOf("year")
            .format("YYYY-MM-DD"),
      });
    });
  };

  const handleReset = () => {
    form.resetFields();
    onReset();
  };

  const disabledDate = (current) => {
    return current && current.year() < new Date().getFullYear();
  };

  return (
    <Form form={form} onFinish={handleFilterSubmit}>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Space>
          <Form.Item name="q[code_cont]">
            <Input placeholder={t("budgets.attributes.reference")} />
          </Form.Item>
          <Form.Item name="q[name_en_or_name_ar_cont]">
            <Input placeholder={t("budgets.attributes.name")} />
          </Form.Item>
          <Form.Item name="q[kind_eq]">
            <Select placeholder={t("budgets.attributes.budget_type")}>
              <Option value="0">{t("budgets.attributes.accounts_budget")}</Option>
              <Option value="1">{t("budgets.attributes.assets_budget")}</Option>
              <Option value="2">{t("budgets.attributes.products_budget")}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="q[fiscal_year_eq]">
            <DatePicker picker="year" disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item>
            <Button icon={<FilterOutlined />} type="primary" htmlType="submit">
              {t("budgets.filter")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button icon={<RedoOutlined />} onClick={handleReset}>
              {t("budgets.reset")}
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default FilterForm;
