//@ts-nocheck
import React from "react";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { HeaderData } from "types/referrals";

const ReferralHeader = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const headerData: HeaderData = {
    title: t("referrals.index.title"),
    description: t("referrals.index.subtitle"),
  };

  return (
    <>
      <Row justify="start" style={{ margin: "0 3em", width: "89%" }}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Title
              level={3}
              style={{
                textAlign: "start",
                wordBreak: "keep-all",
              }}
            >
              {headerData.title}
            </Title>
            <Title
              level={4}
              style={{
                margin: 2,
                fontWeight: "normal",
                textAlign: "start",
                wordBreak: "keep-all",
              }}
            >
              {headerData.description}
            </Title>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ReferralHeader;
