//@ts-nocheck
import { InboxOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

let allowedFileTypes = [
  "image/tiff",
  "image/raw",
  "image/svg",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "application/plain",
  "application/doc",
  "application/docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/txt",
  "application/x-ole-storage",
  "application/xlsx",
  "application/xls",
  "application/xlslx",
  "application/pdf",
  "application/vnd.ms-excel",
  "application/msword",
  "application/zip",
  "application/x-zip",
  "application/x-zip-compressed",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/octet-stream",
  "text/plain",
  "text/csv",
  "text/txt",
  "audio/mp3",
  "audio/mp4",
  "audio/mpeg",
  "video/x-ms-asf",
  "audio/x-ms-wmv",
  "video/mp4",
  "video/x-ms-wmv",
  "video/wmv",
  "application/vnd.ms-asf",
];
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const AttachmentField = () => {
  const { t } = useTranslation();
  const beforeUpload = (file) => {
    const isAllowedType = allowedFileTypes.includes(file.type);
    if (!isAllowedType) {
      message.error(
        t("forms.messages.attachments.error.attachment_content_type")
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      message.error(t("forms.messages.attachments.error.attachment_file_size"));
    }

    if (!isAllowedType || !isLt2M) {
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  return (
    <Form.Item label={t("budgets.accounts_budgeting.fields.attachments.name")}>
      <Form.Item
        name="attachment"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload.Dragger beforeUpload={beforeUpload} multiple name="attachment">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t("forms.placeholders.drop_zone_placeholder")}
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Form.Item>
  );
};

export default AttachmentField;
