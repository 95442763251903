//@ts-nocheck
import React from 'react'
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import LineItem from './item';



const DimensionForm = ({ form }: {form: FormInstance}) => {
  const { t } = useTranslation();

  return (
    <Form
        labelWrap
        form={form}
        scrollToFirstError
        validateMessages={{
          required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
        }}
        labelCol={{span: 6, style: { textAlign: 'start', fontWeight: 'normal !important' }}}
        wrapperCol={{span: 16}}
    >
        {Array(15).fill(0).map((_, idx) => (
          <LineItem key={`ls-item-${idx}`} idx={idx} />
        ))}
    </Form>  )
}

export default DimensionForm