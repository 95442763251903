//@ts-nocheck
import React, { ForwardRefRenderFunction } from 'react'
import * as AntD from 'antd'
import token from 'themes/light.json';

interface antComponentProps {
  component: string;
}

const antComponent = React.forwardRef<HTMLElement, antComponentProps>(({component, ...props}, ref) => {
  const AntComponent = AntD[component];
  return (
    <AntD.ConfigProvider theme={token}>
      <AntComponent {...props} ref={ref} />
    </AntD.ConfigProvider>
  )
})

export default antComponent