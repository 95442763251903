//@ts-nocheck
import React from "react";
import { Typography } from "antd";
import { CardData } from "types/referrals";

const CardContent: React.FC<CardData> = ({ title, description, alignment = "start" }) => {
  const { Title } = Typography;

  return (
    <>
      <Title
        level={2}
        style={{ margin: 0, textAlign: alignment, wordBreak: "keep-all" }}
      >
        {title}
      </Title>
      <Title
        level={5}
        style={{
          margin: 0,
          fontWeight: "normal",
          textAlign: alignment,
          wordBreak: "keep-all",
        }}
      >
        {description}
      </Title>
    </>
  );
};

export default CardContent;