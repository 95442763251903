export const getCurrencySymbol = (locale: string, currency: string) => {
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d|٠/g, "")
    .trim();
};

export const formatAmountWithCurrency = (
  value: number | string,
  locale?: string,
  currency?: string
) => {
  const normalizedValue: number =
    typeof value === "number" ? value : parseFloat(value);

  const formattedNumber = new Intl.NumberFormat("en-EG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(normalizedValue));

  const currencySymbol =
    currency && locale && getCurrencySymbol(locale, currency);

  const formattedValue =
    normalizedValue < 0
      ? `(${formattedNumber}${currencySymbol ? " " + currencySymbol : ""})`
      : `${formattedNumber}${currencySymbol ? " " + currencySymbol : ""}`;

  return formattedValue;
};
