//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { Form, Input, Flex, Button, message, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import { theme } from 'antd';
import QPageLayout from 'components/layout/QPageLayout';
import BackButton from 'components/layout/BackButton';
import axios from 'axios';
import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import { getParentDimensionValues } from 'api/dimensions';
import { DimensionValueResponse } from 'types/dimensions';

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
          title: t('activerecord.attributes.dimension_value.new'),
      },
  ]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 4 }
    
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 8 },
  },
};


const NewDimensionValue = () => {
  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [parentValues, setParentValues] = useState([]);
  const dimensionID = location.pathname.match(/\d+/)[0]
  const [isSelectingParent, setIsSelectingParent] = useState(false);
  const [parentID, setParentID] = useState('');
  const breadCrumbData = useBreadCrumbData();
 
  const nextCodeQuery = useQuery(['nextCode', parentID], async () => (await axios.get<{next_code: string, dimension: any}>(`/tenant/dimensions/${dimensionID}/values/next_code.json?parent_id=${parentID}`)).data);

  const dimensionParentValuesQuery = useInfiniteQuery<DimensionValueResponse>(['parenDimensions'], getParentDimensionValues.bind(null, { dimensionID }), {
    getNextPageParam: (lastPage) => lastPage.pagination.next_page,
    enabled: isSelectingParent
  })

  useEffect(() => {
    if(dimensionParentValuesQuery.data) {
      const { pages } = dimensionParentValuesQuery.data;
      setParentValues(() => {
        return pages.map(page => page.records.map(record => ({ label: `${record.code} - ${i18next.language === 'en'? record.name_en : record.name_ar}`, value: record.id }))).flat();
      })
    }
  }, [ dimensionParentValuesQuery.data ])

  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.post(`/tenant/dimensions/${dimensionID}/values.json`, values)
    },
    onSuccess: () => {
      window.go_back();
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    }
  })
  
  useEffect(() => {
    if(nextCodeQuery.data) {
      form.setFieldValue('code', nextCodeQuery.data.next_code);
      form.setFieldValue('dimension_name', i18next.language === 'en' ? nextCodeQuery.data.dimension.name_en : nextCodeQuery.data.dimension.name_ar);
      form.setFieldValue('dimension_code', nextCodeQuery.data.dimension.code);
    }
  }, [nextCodeQuery.data])
  
  return (
    <QPageLayout messageHolder={contextHolder} title={t('activerecord.attributes.dimension_value.new')} actionBtns={
      <BackButton />
    } breadCrumbData={breadCrumbData} >
      <Form
        labelWrap
        form={form}
        name="code"
        onFinish={mutation.mutate}
        scrollToFirstError
        validateMessages={{
          required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
        }}
        {...formItemLayout}
      >
          <Form.Item
            name="dimension_name"
            label={t("activerecord.attributes.dimension_value.dimension_code_name")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="dimension_code"
            label={t("activerecord.attributes.dimension_value.dimension_code")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="value_type"
            label={t("activerecord.attributes.dimension_value.value_type")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Select
              options={[{ value: "parent_value", label: t("activerecord.attributes.dimension_value.value_types.parent_value") }, { value: "standard", label: t("activerecord.attributes.dimension_value.value_types.standard") }]}
              />
          </Form.Item>
          <Form.Item
            name="parent_id"
            label={t("activerecord.attributes.dimension_value.parent_dimension_value")}
          >
            <Select
                onPopupScroll={() => { if(dimensionParentValuesQuery.hasNextPage) dimensionParentValuesQuery.fetchNextPage() }}             
                options={parentValues}
                loading={dimensionParentValuesQuery.isLoading}
                onClick={() => setIsSelectingParent(true)}
                onChange={(value) => setParentID(value)}
            />
          </Form.Item>
          <Form.Item
            name="code"
            label={t("activerecord.attributes.dimension_value.id")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name_ar"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_ar")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item
            name="name_en"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_en")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { offset: 0 }, lg: { offset: 12 } }}>
            <Flex gap="small" justify='end'>
              <Button type="primary" disabled={mutation.isLoading || mutation.isSuccess ? true : false} htmlType="submit">
                {t("forms.buttons.save")}
              </Button>
              <Button danger href='/tenant/dimensions'>
                {t("forms.buttons.cancel")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
    </QPageLayout>
  )
}

export default NewDimensionValue