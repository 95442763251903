//@ts-nocheck
import React from "react";
import { useTranslation } from "react-i18next";
import QPageLayout from "components/layout/QPageLayout";
import BudgetTable from "components/tenant/budgets/BudgetTable";
import BudgetActionButton from "components/tenant/budgets/BudgetActionButton";
import { getBudgetsPermissions } from "api/budgets";
import { useQuery } from "react-query";

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
    {
      title: <a href="/tenant/budgets">{t("budgets.index.index_title")}</a>,
    },
    {
      title: t("budgets.name"),
    },
  ];
};

const Budget = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["budgets_permissions"],
    queryFn: () => getBudgetsPermissions(),
  });

  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  return (
    <QPageLayout
      title={t("budgets.name")}
      actionBtns={<BudgetActionButton permissions={!isLoading && data} />}
      breadCrumbData={breadCrumbData}
    >
      <BudgetTable permissions={!isLoading && data} />
    </QPageLayout>
  );
};

export default Budget;
