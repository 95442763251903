//@ts-nocheck
import { Form, Tabs, theme } from "antd";
import { TabsProps } from "antd/lib";
import { useInteractiveFilter, useMediaQuery } from "hooks/app";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentTypes } from "types/recurring_transactions";

type Props = Pick<
  ReturnType<typeof useInteractiveFilter>,
  "form" | "page" | "update"
>;

const translationTerms = {
  All: {
    tabTitle: "recurring_transactions.index.all",
  },
  Invoices: {
    tabTitle: "activerecord.external_documents.invoice.title",
  },
  Bills: {
    tabTitle: "activerecord.attributes.contact.bills",
  },
  SimpleBills: {
    tabTitle: "activerecord.external_documents.simple_bill.title",
  },
  modal: {
    title: "recurring_transactions.select_documents.title",
    saveSelections: "recurring_transactions.actions.save_selection",
    cancel: "recurring_transactions.actions.cancel",
  },
  tabs: {
    unauthorizedMessage:
      "recurring_transactions.select_documents.unauthorized_message",
  },
};

type TabKeys = DocumentTypes | "All";

const documentsKeys: TabKeys[] = ["All", "Invoices", "Bills", "SimpleBills"];

const documentsTokens = Object.fromEntries(
  documentsKeys.map((id) => [
    id,
    {
      translations: translationTerms[id].tabTitle,
      filterValue: id === "All" ? null : id.slice(0, -1),
      id,
    },
  ])
) as {
  [key in DocumentTypes]: {
    translations: string;
    filterValue: string;
    id: key;
  };
};

const IndexTabs = ({ form, page, update }: Props) => {
  const [activeTab, setActiveTab] = useState<TabKeys | null>("All");

  const statusField = Form.useWatch("q[document_type_eq]", form);

  const { token } = theme.useToken();

  const tabItems = useTabItems();

  const isLargeScreen = useMediaQuery("lg:(size>=992)");

  const tabChangeHandler: TabsProps["onChange"] = (activeKey: TabKeys) => {
    setActiveTab(activeKey);
    form.resetFields();
    form.setFieldValue(
      "q[document_type_eq]",
      documentsTokens[activeKey].filterValue
    );
    update();
  };

  useLayoutEffect(() => {
    setActiveTab(!statusField ? "All" : documentsTokens[`${statusField}s`]?.id);
  }, [statusField]);

  return (
    <Tabs
      size={isLargeScreen ? "large" : "middle"}
      activeKey={activeTab ?? ""}
      tabBarGutter={token.marginXS}
      type="card"
      items={tabItems}
      onChange={tabChangeHandler}
    />
  );
};

const useTabItems = (): TabsProps["items"] => {
  const { t } = useTranslation();

  return documentsKeys.map((id) => {
    return {
      key: id,
      label: t(documentsTokens[id].translations),
    };
  });
};

export default IndexTabs;
