//@ts-nocheck
import React, { useState } from 'react'
import { Typography, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { List } from 'antd';
import QPageLayout from 'components/layout/QPageLayout';
import BackButton from 'components/layout/BackButton';
import { useQuery } from 'react-query';
import { getDimensions } from 'api/dimensions';
import type { DataType as DimensionType } from './index';
import type { Pagination } from 'types/app';
import ListItem from 'components/tenant/dimensions/ListItem';

const { Title } = Typography;




export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
          title: t('activerecord.attributes.dimension.configuration.title'),
      },
  ]
}


const DimensionConfiguration = ({}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContext] = Modal.useModal();
  
  
  const breadCrumbData = useBreadCrumbData();
  const { data, isLoading } = useQuery<{records: DimensionType[], pagination: Pagination}>(['dimensions', page], getDimensions.bind(null, { page, 'q[sorts]': ['global desc', 'created_at desc'] }));
  

  return (
    <QPageLayout messageHolder={contextHolder} title={t('activerecord.attributes.dimension.configuration.title')} actionBtns={
      <BackButton />
    } breadCrumbData={breadCrumbData} >
      <>
        <List
          loading={isLoading}
          header={<Title level={5}>{t('activerecord.attributes.dimension.configuration.codes')}</Title>}
          bordered
          dataSource={data?.records ?? []}
          pagination={{ total: data?.pagination.total_entries || 0, pageSize: 15, onChange: (page) => setPage(page), showSizeChanger: false, hideOnSinglePage: true }}
          renderItem={(item) => (
            <ListItem key={item.id} dimensionRecord={item} messageApi={messageApi} modal={modal} />
          )}
        />
        {modalContext}
      </>
    </QPageLayout>
  )
}

export default DimensionConfiguration