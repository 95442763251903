//@ts-nocheck
import {createContext} from 'react';
import { Subject } from 'rxjs';
import { Dimension } from 'types/dimensions';

interface DimensionContextType { 
  globalValues: Map<string, string>;
  portalObservable: Subject<Map<string, {dimension_value_id: number, dimension_id: number}[]>>
}

export const formObservable = new Subject<{dimensions: Dimension[], trID: string, lineItemID?: string }>();
export const ModalContext = createContext({dataRef: new Map<string, {id: number, dimension_value_id: number, dimension_id: number}[]>(), appliedRef: new Map<string, [boolean, {value: number; id: number;}]>(), updateTmpApplyList: (key, check, value) => {}});

export default createContext<DimensionContextType>({} as DimensionContextType);